var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout',{class:{
    'settings__borderLess': _vm.settings.isBorderless,
    'settings__squaredBorders': _vm.settings.isSquaredBorders,
    'settings__fixedWidth': _vm.settings.isFixedWidth,
    'settings__menuShadow': _vm.settings.isMenuShadow,
    'settings__menuTop': _vm.settings.isMenuTop
  }},[(!_vm.settings.isMobileView && !_vm.settings.isMenuTop)?_c('a-layout-sider',{class:_vm.settings.isLightTheme ? [_vm.$style.sider, _vm.$style.light] : _vm.$style.sider,attrs:{"width":256,"collapsible":"","collapsed":_vm.settings.isMenuCollapsed},on:{"collapse":_vm.onCollapse}},[_c('cui-menu-left',{attrs:{"settings":_vm.settings}})],1):_vm._e(),(_vm.settings.isMobileView)?_c('div',[_c('div',{class:_vm.$style.handler,on:{"click":_vm.toggleMobileMenu}},[_c('div',{class:_vm.$style.handlerIcon})]),_c('a-drawer',{attrs:{"closable":false,"visible":_vm.settings.isMobileMenuOpen,"placement":"left","wrapClassName":_vm.$style.mobileMenu},on:{"close":_vm.toggleMobileMenu}},[_c('cui-menu-left',{attrs:{"settings":_vm.settings,"withDrawer":true}})],1)],1):_vm._e(),(_vm.settings.isMenuTop && !_vm.settings.isMobileView)?_c('cui-menu-top',{attrs:{"settings":_vm.settings}}):_vm._e(),_c('my-company-settings',{attrs:{"settings":_vm.settings,"company":_vm.companyInformation}}),_c('cui-settings',{attrs:{"settings":_vm.settings}}),_c('a-layout',[_c('a-layout-header',[_c('cui-topbar')],1),_c('cui-breadcrumbs',{attrs:{"settings":_vm.settings}}),_c('a-layout-content',[_c('div',{staticClass:"utils__content"},[_c('router-view')],1)]),_c('footer',[_c('div',{class:_vm.$style.footer},[_c('h6',[_vm._v("v"+_vm._s(_vm.$appVersion))])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }